"use client";
import { SVGProps } from "react";

export const NotFoundSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={492}
    height={492}
    fill="none"
    {...props}
  >
    <path
      fill="#E3F0FF"
      d="M255.266 416.97c77.759 0 140.794-3.231 140.794-7.216 0-3.985-63.035-7.216-140.794-7.216-77.758 0-140.794 3.231-140.794 7.216 0 3.985 63.036 7.216 140.794 7.216Z"
    />
    <path
      fill="#FF8D76"
      d="M316.93 408.278s-2.706-4.592 3.116-6.806c5.822-2.214 19.024-3.608 17.712-8.118-1.312-4.51-5.822-12.628 5.494-20.336 11.234-7.626 9.02-10.906 10.578-10.168 1.558.738 5.33 5.248 4.674 9.512-.656 4.264-3.772 5.33-2.296 6.724 1.476 1.312 6.478-3.526 6.478-8.036s.246-7.79 3.526-5.904c3.198 1.886 2.788 4.592 5.084 2.542s3.772-6.15 2.788-12.218c-.902-6.068 2.05-10.578 6.314-10.742 4.264-.164 13.12 7.79 13.858 19.844.656 12.054-6.806 21.894-5.74 24.928 1.066 3.116 5.74 1.64 8.2-2.788 2.378-4.428 8.282 2.542 6.724 8.61-1.64 6.068-3.116 12.71-6.724 13.94-3.608 1.23-67.65.246-72.488.246-4.92 0-6.396.082-7.298-1.23Z"
    />
    <path
      fill="#FEC272"
      d="M332.1 406.228c3.362-.082 13.448 0 12.218-5.658-1.23-5.658-3.362-8.036.164-12.874 3.526-4.838 7.38-1.886 7.544.574.164 2.378-.82 7.216 3.362 6.56 4.182-.656 4.264-11.972 7.79-12.874 3.526-.902 4.592 2.542 5.084 5.904.574 3.362 4.674-.246 6.314-4.264 1.64-4.018 1.476-9.512 5.248-11.152 3.772-1.64 5.658 7.79 4.018 16.4-1.64 8.61-2.952 12.874-.902 13.694 2.05.82 6.068-1.23 7.954 1.886 1.886 3.116-.902 5.248-8.61 5.248h-52.89c-6.314-.082-5.002-3.28 2.706-3.444ZM108.896 275.848c-2.46 4.346-9.102 2.132-12.218-2.788-3.116-5.002-7.79-2.05-6.724 7.79 1.066 9.758 5.002 16.236 12.054 20.91 7.052 4.674 15.006 6.56 13.12 9.758-1.886 3.198-4.838 10.496 5.74 14.186 10.578 3.772 25.912 2.05 33.784-6.806 7.954-8.856 7.954-28.372 4.264-38.868-3.608-10.496-10.906-17.138-13.284-21.976-2.378-4.838-6.806-1.476-6.068 3.034.82 4.51 2.296 7.134-.902 7.216-3.198.082-5.33-10.906-14.924-12.956-9.594-2.132-11.152 3.608-17.22.328-6.068-3.362-8.282-3.034-8.692 2.214-.41 5.33 3.608 11.152 7.052 12.054 5.166 1.312 5.658 2.952 4.018 5.904Z"
    />
    <path
      fill="#FF8D76"
      d="M123 280.932c.902 4.674-2.952 9.758-9.676 6.806-6.724-2.952-9.102-.574-5.658 5.658S118.818 305.368 123 307.5c4.182 2.132 1.312 5.248 1.886 8.446.574 3.198 9.922 8.036 19.352-2.296 9.43-10.332 9.512-23.452 6.806-33.046-2.706-9.512-6.314-8.036-6.314-4.838 0 3.198.41 9.512-2.05 8.856-2.378-.656-5.74-5.33-8.856-10.742-5.33-9.266-18.368-16.81-20.09-9.676-1.23 5.002 7.954 9.594 9.266 16.728Z"
    />
    <path
      fill="#FEC272"
      d="M313.158 250.346c-11.808-9.594-22.55-26.814-24.928-57.728-1.722-22.386 5.74-32.308 13.448-35.998 7.626-3.608 7.626-7.216 11.07-6.232s2.624 8.856-.574 12.71c-3.198 3.854-3.608 9.84 1.23 10.25 4.838.41 5.412-8.282 6.232-16.318.82-8.036 2.788-11.234 12.3-15.662 9.512-4.428 14.514-7.626 16.482-14.104 2.05-6.478 4.018-6.068 7.626-4.264 3.608 1.804 4.838 10.25 1.804 13.448-3.034 3.198-5.412 5.658-5.002 8.446.41 2.788 1.804-1.64 10.086-5.658 8.282-4.018 11.644-16.072 12.71-22.304 1.066-6.232 3.198-7.872 9.02-5.002 5.822 2.788 13.284 13.858 11.234 28.946-2.05 15.088-12.3 25.338-13.858 33.456-1.64 8.036 3.444 9.84 8.692 6.806s2.378-12.054 5.248-16.318c2.788-4.264 11.48 4.838 14.104 20.09 2.624 15.252 6.642 39.524-16.072 62.238-23.452 23.288-57.81 21.976-80.852 3.198Z"
    />
    <path
      fill="#FF8D76"
      d="M321.932 178.76c5.74-9.348 11.644-9.02 11.89-5.658.164 3.444-4.592 8.282-2.214 10.66 2.378 2.378 5.658-1.804 6.642-7.872s6.806-9.43 12.874-12.3c6.068-2.788 8.692-8.856 11.644-9.02 2.952-.164 4.018 6.232 2.05 10.086-2.05 3.854-9.266 5.822-9.266 11.234s5.33 5.74 7.626 1.23c2.296-4.51 1.722-6.97 6.56-7.38 4.838-.41 4.428 11.152 1.23 15.416-3.198 4.264-8.692 7.626-6.15 11.808 2.542 4.182 7.216 0 9.758-2.788 2.542-2.788 7.626.246 8.446 5.658.82 5.33-9.676 14.514-9.02 19.188.656 4.674 4.838 3.526 7.216.246 2.378-3.198 5.494.902 4.674 6.314-.82 5.412-15.416 23.37-41.492 16.482-37.884-9.922-32.718-46.494-22.468-63.304Z"
    />
    <path
      fill="#E1ECFF"
      d="M185.648 167.608 270.6 93.562c.82-.738 2.05-.656 2.788.164.738.82.656 2.132-.164 2.87l-83.558 76.424-4.018-5.412ZM177.694 182.696 131.118 80.032c-.492-.984 0-2.214.984-2.624.984-.492 2.214-.082 2.706.984l49.2 102.09-6.314 2.214Z"
    />
    <path
      fill="#0D2560"
      d="M178.186 190.814c8.468 0 15.334-6.865 15.334-15.334s-6.866-15.334-15.334-15.334c-8.469 0-15.334 6.865-15.334 15.334s6.865 15.334 15.334 15.334Z"
    />
    <path
      fill="#273D7A"
      d="M177.529 170.478c4.076 0 7.38-2.056 7.38-4.592 0-2.536-3.304-4.592-7.38-4.592-4.075 0-7.38 2.056-7.38 4.592 0 2.536 3.305 4.592 7.38 4.592Z"
    />
    <path
      fill="#1C3177"
      d="M298.89 364.408c-.246 6.97-8.364 7.216-12.382-.82-1.886-3.854-5.002-10.414-7.708-16.154a1352.856 1352.856 0 0 1-5.412-11.726l23.37 2.378s.492 4.1 1.066 9.266c.656 5.904 1.23 13.284 1.066 17.056Z"
    />
    <path
      fill="#072359"
      d="M297.824 347.352c-1.148.246-2.46.492-3.854.738-4.838.82-10.414.246-15.088-.656-2.952-6.314-5.412-11.726-5.412-11.726l23.37 2.378c-.082 0 .492 4.1.984 9.266Z"
    />
    <path
      fill="#1C3177"
      d="M140.384 337.676c-2.46 6.56 4.838 9.922 11.644 4.1 3.28-2.788 8.692-7.626 13.448-11.89 5.166-4.674 9.594-8.692 9.594-8.692l-22.468-6.97s-2.05 3.608-4.592 8.118c-2.788 5.33-6.314 11.808-7.626 15.334Z"
    />
    <path
      fill="#072359"
      d="M148.091 322.424c.984.738 2.05 1.476 3.28 2.214 4.1 2.624 9.43 4.264 14.186 5.33 5.166-4.674 9.594-8.692 9.594-8.692l-22.468-6.97s-2.05 3.608-4.592 8.118Z"
    />
    <path
      fill="#0D2560"
      d="M358.504 249.28c-3.116 24.518-5.904 50.512-11.234 68.47a58.226 58.226 0 0 1-2.214 6.396c-6.806 17.712-19.188 14.104-35.178 15.58-15.99 1.476-20.828 1.312-20.828 1.312l4.1-24.928 19.516-117.752 38.294 3.362s2.05 1.476 3.772 4.264c1.804 2.87 7.298 15.58 3.772 43.296Z"
    />
    <path
      fill="#072359"
      d="M347.27 317.75a58.226 58.226 0 0 1-2.214 6.396c-6.806 17.712-19.188 14.104-35.178 15.58-15.99 1.476-20.828 1.312-20.828 1.312l4.1-24.928 54.12 1.64Z"
    />
    <path
      fill="#142966"
      d="M147.19 180.892s10.004-5.248 21.32-5.658c11.316-.41 100.86 8.528 130.134 11.726 29.274 3.198 41.902 7.216 47.806 10.332 5.904 3.116 8.282 8.692 8.282 8.692l-46.986-4.264-160.556-20.828Z"
    />
    <path
      fill="#273D7A"
      d="m129.314 289.542 5.986-92.332c.656-10.66 10.168-18.614 20.746-17.384l152.192 17.22c9.512 1.066 16.318 9.594 15.416 19.024L313.65 316.848c-1.558 15.498-15.908 26.404-31.242 23.862l-130.134-22.058c-13.94-2.378-23.862-15.006-22.96-29.11Z"
    />
    <path
      fill="#FEC272"
      d="m271.83 319.964 10.988-106.518c.492-4.51 4.51-7.79 9.02-7.298l19.106 2.132c4.51.492 7.708 4.592 7.216 9.02l-12.3 106.6c-.492 4.51-4.674 7.708-9.184 7.134l-17.712-2.214c-4.428-.492-7.626-4.428-7.134-8.856Z"
    />
    <path
      fill="#F9A95A"
      d="M313.322 229.354c0 3.444-1.23 6.56-3.198 9.02-1.394 1.64-3.034 3.034-5.002 3.936-1.804.902-3.854 1.312-6.068 1.312-7.872 0-14.268-6.396-14.268-14.268 0-3.28 1.148-6.314 2.952-8.692 1.312-1.722 2.952-3.116 4.92-4.1 1.886-.984 4.1-1.476 6.396-1.476 7.79 0 14.268 6.396 14.268 14.268Z"
    />
    <path
      fill="#99ADF9"
      d="M312.584 226.976c0 3.116-1.066 5.986-2.952 8.282a12.335 12.335 0 0 1-4.592 3.608c-1.722.82-3.526 1.23-5.576 1.23-7.216 0-13.12-5.822-13.12-13.12 0-3.034.984-5.74 2.706-7.954 1.23-1.558 2.706-2.87 4.51-3.69a12.959 12.959 0 0 1 5.822-1.394 13.003 13.003 0 0 1 13.202 13.038Z"
    />
    <path
      fill="#C5DFFF"
      d="M309.713 235.258a12.335 12.335 0 0 1-4.592 3.608l-15.908-19.844c1.23-1.558 2.706-2.87 4.51-3.69l15.99 19.926Z"
    />
    <path
      fill="#F9A95A"
      d="M300.448 276.75c-3.116 1.23-6.314 1.312-9.266.41a13.364 13.364 0 0 1-5.412-3.116c-1.476-1.312-2.624-3.034-3.444-4.92-2.87-7.134.492-15.252 7.626-18.122 2.952-1.23 6.068-1.312 8.938-.492a14.937 14.937 0 0 1 5.494 2.952c1.558 1.394 2.87 3.116 3.69 5.166 2.952 7.134-.492 15.252-7.626 18.122Z"
    />
    <path
      fill="#99ADF9"
      d="M300.448 273.388a13.473 13.473 0 0 1-8.774.41 13.616 13.616 0 0 1-5.084-2.87c-1.394-1.23-2.46-2.788-3.198-4.674-2.706-6.724.492-14.35 7.134-17.056 2.788-1.148 5.74-1.23 8.446-.492 1.886.492 3.608 1.476 5.166 2.788a13.51 13.51 0 0 1 3.526 4.92c2.706 6.642-.492 14.268-7.216 16.974Z"
    />
    <path
      fill="#C5DFFF"
      d="m304.138 251.412-12.464 22.386a13.616 13.616 0 0 1-5.084-2.87l12.382-22.222c1.968.492 3.69 1.394 5.166 2.706Z"
    />
    <path
      fill="#0D2560"
      d="m300.939 292.084-17.22-2.624c-1.148-.164-1.968-1.23-1.804-2.378.164-1.148 1.148-1.968 2.296-1.804l17.22 2.624c1.148.164 1.968 1.23 1.804 2.378-.164 1.148-1.23 1.968-2.296 1.804ZM299.792 301.596l-17.22-2.624c-1.148-.164-1.968-1.23-1.804-2.378.164-1.148 1.148-1.968 2.296-1.804l17.22 2.624c1.148.164 1.968 1.23 1.804 2.378-.082 1.23-1.148 1.968-2.296 1.804ZM298.726 311.19l-17.22-2.624c-1.148-.164-1.968-1.23-1.804-2.378.164-1.148 1.148-1.968 2.296-1.804l17.22 2.624c1.148.164 1.968 1.23 1.804 2.378-.082 1.148-1.148 1.968-2.296 1.804ZM297.66 320.702l-17.22-2.624c-1.148-.164-1.968-1.23-1.804-2.378.164-1.148 1.148-1.968 2.296-1.804l17.22 2.624c1.148.164 1.968 1.23 1.804 2.378-.082 1.148-1.148 1.968-2.296 1.804Z"
    />
    <path
      fill="#F8FBFF"
      d="m275.848 216.562-.492 5.166-8.692 91.676c-.328 3.116-1.64 5.904-3.69 7.954-2.87 2.952-7.052 4.51-11.48 3.772l-97.99-15.498c-10.25-1.64-17.63-10.824-16.974-21.238l5.248-82.164.164-2.706.328-4.51c.41-6.232 5.986-10.906 12.218-10.168l109.88 13.202c7.052.984 12.136 7.38 11.48 14.514Z"
    />
    <path
      fill="#C5DFFF"
      d="m275.848 216.562-.492 5.166-133.414-18.122.328-4.51c.41-6.232 5.986-10.906 12.218-10.168l109.88 13.202c7.052.902 12.136 7.298 11.48 14.432Z"
    />
    <path
      fill="#fff"
      d="M155.308 198.276c-.246 1.64-1.804 2.87-3.526 2.624-1.722-.246-3.034-1.722-2.788-3.362.246-1.64 1.804-2.87 3.526-2.624 1.722.246 2.952 1.722 2.788 3.362ZM168.264 199.834c-.246 1.64-1.804 2.87-3.526 2.624-1.722-.246-3.034-1.722-2.788-3.362.246-1.64 1.804-2.87 3.526-2.624 1.722.246 2.952 1.722 2.788 3.362ZM181.138 201.392c-.246 1.64-1.804 2.87-3.526 2.624-1.722-.246-3.034-1.722-2.788-3.362.246-1.64 1.804-2.87 3.526-2.624 1.804.164 3.034 1.722 2.788 3.362Z"
    />
    <path
      fill="#E1ECFF"
      d="M262.974 321.358c-2.87 2.952-7.052 4.51-11.48 3.772l-97.99-15.498c-10.25-1.64-17.63-10.824-16.974-21.238l5.248-82.164c1.722 28.454-2.624 60.188 13.202 78.228 23.206 26.486 44.28 26.322 107.994 36.9Z"
    />
    <path
      fill="#99ADF9"
      d="m183.926 237.308-4.1-.656c-1.066-.164-1.968.492-2.05 1.558l-1.23 11.234-5.166-.574c-.902-.164-1.476-1.066-1.148-1.886l3.69-9.02c.492-1.148-.328-2.46-1.558-2.624l-3.362-.574c-.82-.164-1.64.328-1.968 1.066l-5.822 14.432c-.656 1.64.41 3.526 2.214 3.854l12.382 2.132-2.214 20.008c-.082 1.066.656 2.05 1.722 2.214l3.854.574c.984.164 1.886-.492 2.05-1.558l4.428-37.884c.082-1.066-.656-2.132-1.722-2.296ZM242.392 247.066l-4.182-.738c-1.066-.164-2.05.574-2.132 1.558l-1.312 11.644-5.412-.574c-.902-.164-1.476-1.148-1.148-1.968l3.772-9.348c.492-1.148-.328-2.542-1.64-2.788l-3.444-.574a1.87 1.87 0 0 0-2.05 1.066l-6.068 14.924c-.656 1.722.492 3.69 2.296 4.018l12.792 2.132-2.296 20.746c-.082 1.066.656 2.132 1.804 2.296l4.018.574c1.066.164 1.968-.574 2.132-1.558l4.592-39.278c.164-.984-.656-1.968-1.722-2.132ZM203.934 239.932c-8.118-.738-12.874 3.854-14.842 17.384-2.46 16.81 1.968 23.616 10.168 25.502 11.152 2.624 13.12-6.97 15.334-21.156 2.214-14.186-2.542-20.992-10.66-21.73Zm4.018 21.402c-1.066 9.922-4.1 15.58-7.79 14.678-3.936-.984-5.412-5.576-4.264-17.302.902-9.43 3.198-12.628 7.052-12.3 3.854.41 6.068 5.002 5.002 14.924Z"
    />
    <path
      fill="#FF8D76"
      d="M310.944 133.66c-3.936-.082-5.658-9.512-1.968-11.89 3.69-2.378 6.314 2.46 6.396 5.986.082 3.526-.902 6.068-4.428 5.904Z"
    />
    <path
      fill="#FEC272"
      d="M356.454 106.354c-3.608-.082-5.166-8.61-1.722-10.742 3.362-2.132 5.658 2.214 5.74 5.412.082 3.198-.82 5.412-4.018 5.33Z"
    />
    <path
      fill="#FF8D76"
      d="M392.452 88.97c-3.69-.082-5.33-8.856-1.804-11.07 3.526-2.214 5.822 2.296 5.986 5.576.164 3.28-.902 5.658-4.182 5.494Z"
    />
    <path
      fill="#FEC272"
      d="M422.382 156.62c-3.116-.82-2.706-8.774.738-9.922 3.444-1.148 4.592 3.116 4.018 5.986-.574 2.87-1.886 4.674-4.756 3.936ZM108.158 239.768c-3.854-.574-4.592-10.004-.574-11.89 4.018-1.886 5.904 3.116 5.658 6.56-.328 3.526-1.558 5.74-5.084 5.33Z"
    />
    <path
      fill="#FF8D76"
      d="M97.088 209.428c-3.034-.41-3.526-7.79-.492-9.266s4.592 2.378 4.346 5.084c-.164 2.788-1.148 4.592-3.854 4.182Z"
    />
    <path
      fill="#FEC272"
      d="M74.866 258.136c-4.92-.656-5.822-12.792-.738-15.17 5.002-2.378 7.544 3.936 7.216 8.446-.41 4.428-1.968 7.298-6.478 6.724ZM126.444 176.792l-39.032-31.078-7.052 12.874 46.084 18.204ZM128.33 168.018l-37.884-36.982 14.186-9.184 23.698 46.166ZM122.754 123.164l13.284 41.328-1.312-42.558-11.972 1.23Z"
    />
  </svg>
);
